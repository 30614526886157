import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Head from 'components/head'
import { Formik } from 'formik'
const axios = require('axios')
import { navigate } from '@reach/router'

import 'style/register.scss'

const Index = ({ data }) => {
  const fields = JSON.parse(data.buroBorkPage.field)

  return (
    <Layout locale={fields.locale} className="main-container">
      <Head pageTitle={fields.title} locale={fields.locale} />

      <section className="bukazu_register">
        <div className="container">
          <h1>Maak je gratis trial account aan</h1>
          <div className="explain custom-p">
            <p>
              We sturen je een email met daarin de gegevens die je nodig hebt om
              de trial te starten. Je start met een wizard om je door het
              instellen van je eerste accommodatie te ondersteunen.
            </p>
          </div>
          <Formik
            initialValues={{ first_name: '', last_name: '', email: '' }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false)
                axios({
                  url: 'https://api.bukazu.com/graphql',
                  method: 'post',
                  data: {
                    query: `
                    mutation createUser($email: String!, $first_name: String, $last_name: String) {
                      createUser(is_trial: true, email: $email, first_name: $first_name, last_name: $last_name) {
                        id
                      }
                    }`,
                    variables: {
                      email: values.email,
                      first_name: values.first_name,
                      last_name: values.last_name,
                    },
                  },
                }).then(result => {
                  if (result.data.errors) {
                    alert(
                      'Er is iets mis gegaan, probeer het later nog eens of neem contact met ons op'
                    )
                  } else {
                    navigate('/bedankt-voor-u-aanmelding')
                  }
                })
              }, 1000)
            }}
          >
            {formik => (
              <form className="registerForm" onSubmit={formik.handleSubmit}>
                <div className="row custom-col-12">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="first_name">Voornaam</label>
                      <input
                        id="first_name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                        className="form-control"
                        placeholder="Voornaam"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="last_name">Achternaam</label>
                      <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                        placeholder="Achternaam"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        id="email"
                        type="text"
                        className="form-control"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary">
                      Verzenden
                    </button>
                  </div>
                </div>
                {/* <p className="custom-p">
                  By creating account I agree with{' '}
                  <a
                    href="https://user.com/en/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms &amp; Conditions.
                  </a>
                </p> */}
              </form>
            )}
          </Formik>
        </div>
      </section>
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BuroBorkRegister {
    buroBorkPage(slug: { eq: "registreer" }) {
      id
      field
    }
  }
`

export default Index
